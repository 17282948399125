import gql from 'graphql-tag'

export const GET_DASHBOARD_INFO = gql`
    query DisplayDashboardInfoType($tokenInput: TokenInput!){
        getDashboardInfo(tokenInput: $tokenInput){
            success
            totalTransaction
            transactionLastmonth
            totalServiceFee
            serviceFeeLastmonth
            totalUsers
            totalHosts
            error
        }
    }
`

export const GET_ALL_UPCOMING_SESSIONS = gql`
    query DisplayBookingOutput($tokenInput: TokenInput!, $pagination: PaginationInput, $tzinfo: String){
        allUpcomingSessions(tokenInput: $tokenInput, pagination: $pagination, tzinfo: $tzinfo){
            success
            error
            bookedList  {
                id
                host{
                    id
                    username
                    firstName
                    lastName
                    email
                    profileImg
                }
                guest   {
                    id
                    username
                    firstName
                    lastName
                    email
                    profileImg
                }
                startTime
                endTime
                sessionType {
                    id
                    title
                    length
                    charge
                }
            }
            first
            skip
            totalBookings
        }
    }
`

export const UPDATE_SESSION_START_TIME = gql`
    mutation UpdateStartTimeMutation($sessionData: UpdateStartTimeInput!){
        updateSessionStartTime(sessionData: $sessionData){
            success
            error
        }
    }
`

export const UPDATE_SERVICE_FEE = gql`
    mutation UpdateServiceFeeMutation($serviceData: ServiceFeeInput!) {
        updateServiceFee(serviceData:$serviceData){
            success
            error
        }
    }
`

export const DELETE_USER = gql`
    mutation DeleteUserMutation($userData: AdminTalentInput!) {
        deleteUser(userData:$userData){
            success
            error
        }
    }
`

export const SET_FEATURED_HOST = gql`
    mutation SetFeaturedHostMutation($featuredData: AdminTalentInput!) {
        setFeaturedHost(featuredData:$featuredData){
            success
            error
        }
    }
`

export const GET_HOST_INFO = gql`
    query DisplayHostInfoType($hostInput: GetHostInput!) {
        getHostInfo(hostInput:$hostInput){
            success
            error
            host {
                id
                user { 
                    id
                    username
                    firstName
                    lastName
                    email
                    verifiedEmail
                    profileImg
                    phoneNumber
                    zipcode
                    city
                }
                shortIntro
                longIntro
                verified
                featured
                followersCount
                introVideo
                areas {
                    id
                    name
                    order
                }
                price
                serviceFee
            }
    	    totalEarned
            earnedLastmonth
            totalServiceFee
            serviceFeeLastmonth
            completedSessions
            upcomingSessions
            payouts {
                payoutid
                createdOn
                serviceCharge
                charge
                netPayout
                status
                paidBy {
                    id
                    username
                    firstName
                    lastName
                }
            }
            totalPayouts
        }
    }
`

export const GET_ALL_USER = gql`
    query DisplayUserListOutput($pagination: PaginationInput) {
        getUserList(pagination: $pagination){
            success
            error
                userList {
                id
                username
                firstName
                lastName
                email
                verifiedEmail
                profileImg
                phoneNumber
                zipcode
                city
            }
            totalUsers
            first
            skip
        }
    }
`

export const SEARCH_USER = gql`
    query DisplayUserListOutput($query: String!, $pagination: PaginationInput) {
        searchUser(query:$query,pagination: $pagination){
            success
            error
                userList {
                id
                username
                firstName
                lastName
                email
                verifiedEmail
                profileImg
                phoneNumber
                zipcode
                city
            }
            totalUsers
            first
            skip
        }
    }
`