<template>
  <div id="admin-users">
    <!-- App Bar -->
    <v-app-bar
      app
      color="white"
      light
      flat
    >
      <v-app-bar-nav-icon
        v-if="!sidebarMenu"
        @click.stop="sidebarMenu = !sidebarMenu"
      />
    </v-app-bar>
    <!-- Sidebar -->
    <v-navigation-drawer 
      v-model="sidebarMenu" 
      app
      floating
      :permanent="sidebarMenu"
      :mini-variant.sync="mini"
    >
      <template #prepend>
        <v-list-item two-line>
          <v-list-item-action>
            <v-icon
              v-if="sidebarMenu"
              @click.stop="sidebarMenu = !sidebarMenu"
            >
              mdi-chevron-left
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <router-link
              to="/"
              class="black--text text-decoration-none"
            >
              <img
                width="50%"
                height="70%"
                src="@/assets/images/logo/with-black.png"
                class="mt-3 ml-3"
              >
            </router-link>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.url"
          active-class="yellow"
          class="opacity-1"
        >
          <v-list-item-icon>
            <v-icon color="light-grey">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="light-grey">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      
      <template #append>
        <v-list>
          <v-list-item
            active-class="yellow"
            class="opacity-1"
            @click="logout()"
          >
            <v-list-item-icon>
              <v-icon color="light-grey">
                mdi-logout
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="light-grey">
                {{ $t("logout ") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <!-- Main Container -->
    <v-container
      class="fill-height dashboard-container"
      fluid
    >
      <v-row
        class="fill-height grey mx-7"
        justify="center"
      >
        <v-flex
          xs12
          md4
          my-5
        >
          <v-card
            class="search-box"
            flat
            tile
          >
            <v-text-field
              solo
              placeholder="Eg. Mick Gordon"
              flat
              tile
              hide-details
              color="black"
              background-color="white"
              prepend-inner-icon="mdi-magnify"
              clearable
              @input="searchUser"
              @click:clear="clearSearch()"
            />
          </v-card>
        </v-flex>
        <v-flex
          xs12
        >
          <v-card
            flat
            color="white"
            width="100%"
          >
            <v-card-text class="text-center black--text py-3">
              <span class="text-h5"> {{ $t('all_users') }}</span>
              <v-chip
                class="white--text text--opacity-25 text-body-2 ml-4"
                color="#ADADAD"
                :ripple="false"
                small
              >
                {{ totalUserCount }}
              </v-chip>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-row>
      <v-container>
        <v-row
          no-gutters
          justify="center"
          class="mt-5"
        >
          <template v-if="loading">
            <v-slide-item
              v-for="n in 8"
              :key="n"
            >
              <v-skeleton-loader
                class="ma-2"
                width="221"
                type="card-avatar"
              />
            </v-slide-item>
          </template>
          <template
            v-else
          >
            <v-col
              v-for="(user, index) in paginatedUsers"
              :key="index"
              xs="6"
              md="auto"
            >
              <v-card
                flat
                class="ma-2"
                rounded="lg"
                :ripple="false"
              >
                <v-img
                  :src="user.profileImg ? user.profileImg : $defaultImg"
                  class="white--text align-end"
                  eager
                  width="221"
                  height="230"
                  lazy-src="@/assets/images/default.png"
                  alt="default"
                  gradient="rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)"
                >
                  <v-card-text class="black--text px-5">
                    <p class="ma-1 white--text text-h5">
                      {{ user.firstName }} {{ user.lastName }}
                    </p>
                    <p class="ma-1 white--text pt-1 text-subtitle-1">
                      {{ user.email }}
                    </p>
                  </v-card-text>
                </v-img>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
// graphql
import { SEARCH_USER, GET_ALL_USER } from "@/graphql/admin";

export default {
  data() {
    return {
      sidebarMenu: true,
      toggleMini: false,
      loading: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", url: "/admin/dashboard" },
        { title: "Sessions", icon: "mdi-clock", url: "/admin/sessions" },
        { title: "Hosts", icon: "mdi-calendar-blank", url: "/admin/hosts" },
        { title: "Users", icon: "mdi-help-circle-outline", url: "/admin/users" },
      ],
    
      paginatedUsers: [],
      first:12,
      skip:0,
      totalUserCount: 0,
      records: 0,
    }
  },
  computed: {
    mini() {
      return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
    },
  },
  mounted() {
    this.getAllUser()
    this.loadOnScroll()
  },
  methods:{
    /**
     * Load More Talent On Scroll
     */
    loadOnScroll(){
      window.onscroll = async() => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow && this.records != this.paginatedUsers.length) {
          this.skip += this.first
          await this.getAllUser(true)
        }
      }
    },
    /**
     * Get User Lists
     */
    async getAllUser(isScroll = false) {
      this.loading = !isScroll
      const { data: { getUserList } } = await this.$apollo.query({
        query: GET_ALL_USER,
        variables: {
          pagination:{
            first: this.first,
            skip: this.skip,
          },
        },
      });
      if (getUserList.success) {
        this.paginatedUsers.push(...getUserList.userList)
        this.totalUserCount = getUserList.totalUsers
        this.records = getUserList.totalUsers
      }
      this.loading = false;
    },

    /**
     * Search Talent
     */
    async searchUser(keyword) {
      if(keyword){
        this.loading = true
        this.first = 12,
        this.skip = 0,
        this.paginatedUsers = []
        this.records = 0
        const {
          data: { searchUser },
        } = await this.$apollo.query({
          query: SEARCH_USER,
          variables: {
            query: keyword,
            pagination:{
              first: this.first,
              skip: this.skip,
            },
          },
        });

        if (searchUser.userList) {
          this.paginatedUsers.push(...searchUser.userList)
          this.records = searchUser.totalUsers
        } 
        this.loading = false
      } else {
        this.clearSearch()
      }
    },

    /**
     * Clear Search
     */
    async clearSearch(){
      this.records = 0
      this.first = 12,
      this.skip = 0,
      this.totalUserCount = 0
      this.paginatedUsers = []
      await this.getAllUser()
    },

    /**
     * Logout
     */
    async logout() {
      await this.$store.dispatch("logout");
      window.location = "/";
    },
  },
}
</script>
<style lang="scss" scoped>
  #admin-users {
    min-height: calc(100vh - 75px);
    background-color: #F1F3F6;
  }
  .dashboard-container {
    margin-top: 9px;
  }
</style>
